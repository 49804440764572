import { useState, useEffect, useMemo } from 'react';
export default function useCoupon(podcastData = {}) {
  const couponData = podcastData?.coupon_data;
  const [couponText, setCouponText] = useState(couponData?.name || '');
  const coupon = useMemo(() => {
    if (!couponData) return {};
    return couponData;
  }, [couponData]);
  const isCouponValid = useMemo(() => !!couponData, [couponData]);

  function readCouponText() {
    // Reading Coupon Text from URL Parameter
    const queryString = window?.location?.search;
    const params = new URLSearchParams(queryString);
    return params.get('coupon');
  }

  function getDiscountedPrice(price) {
    let discountedPrice;
    if (coupon.discount_type === 'flat') {
      discountedPrice = price - coupon.discount;
    } else {
      discountedPrice = price * (1 - coupon.discount / 100);
    }
    return Number(discountedPrice);
  }

  const discountedPriceObject = useMemo(() => {
    if (!isCouponValid) return {};
    if (podcastData.audio_type === 'podcast') {
      if (coupon.plan === 'monthly') {
        return { price: getDiscountedPrice(podcastData.price) };
      }
      return { annual_price: getDiscountedPrice(podcastData.annual_price) };
      // Kept here for clarity purposes
      // eslint-disable-next-line no-else-return
    } else {
      return { price: getDiscountedPrice(podcastData.price) };
    }
  }, [coupon]);

  useEffect(() => {
    if (!couponText) {
      // eslint-disable-next-line no-underscore-dangle
      const _couponText = readCouponText();
      if (_couponText) setCouponText(_couponText);
    }
  }, []);

  return {
    coupon,
    isCouponValid,
    getDiscountedPrice,
    discountedPriceObject,
  };
}
