import queryString from 'querystring';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Router from 'next/router';
import cookies from 'lib/next-cookies';
import Cookies from 'lib/js-cookie';
import { openPodcastDetail } from 'apis/podcast';

import { audioSeriesEpisodeList, episodeList } from 'apis/episode';
import { getSubdomain, reservedNames } from 'utils/helpers';
import PodcastPage from 'components/PodcastPage';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_ID);

const ProductPublicPage = ({
  podcastData,
  episodeData,
  host,
  slug,
  token,
  subscriptionConfirmation,
  isPodcast,
  spotifyShowUri,
}) => {
  let publicPageHost = '';

  useEffect(() => {
    if (window !== undefined) {
      publicPageHost = host || window?.location?.host;
    }
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <PodcastPage
        episodeData={episodeData}
        podcastData={podcastData}
        isPodcast={isPodcast}
        host={publicPageHost}
        slug={slug}
        token={token}
        subscriptionConfirmation={subscriptionConfirmation}
        spotifyShowUri={spotifyShowUri}
      />
    </Elements>
  );
};

ProductPublicPage.getInitialProps = async (context) => {
  const { query, res } = context;
  const { token } = cookies(context);
  const slug = query['podcast-name'];

  const subdomain =
    context?.req?.headers?.host
      ?.split('.')
      .slice(0, context?.req?.headers?.host.includes('localhost') ? -1 : -2) ||
    getSubdomain();
  const host = context?.req?.headers?.host;
  if (!(subdomain?.length > 0 && !reservedNames.includes(subdomain[0]))) {
    if (res) {
      res.writeHead(301, {
        Location: '/404',
      });
      res.end();
    } else {
      Router.push('/404');
    }
    return {};
  }

  // if (!query.coupon && coupon && res) {
  //     res.writeHead(302, {
  //         Location: `${routes.podcastPage(slug)}?coupon=${coupon}`,
  //     });
  //     res.end();
  //     return {};
  // }
  // else podcast
  const isAuthRequired = !!token;

  const queryParams = {};
  if (query.coupon) {
    queryParams.coupon = query.coupon;
  }

  const response = await openPodcastDetail(
    slug,
    isAuthRequired,
    token,
    queryParams,
  );

  let subscriptionConfirmation = false;
  if (response?.status === 404) {
    if (res) {
      res.writeHead(301, {
        Location: '/404',
      });
      res.end();
    } else {
      Router.push('/404');
    }
    return {};
  }

  if (response?.status === 200) {
    if (response?.data?.user_subscribed) {
      if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
        Cookies.remove('subscriptionConfirmation', { path: '/' });
      } else {
        Cookies.remove('subscriptionConfirmation', {
          path: '/',
          domain: '.mumbler.io',
        });
      }
    } else {
      subscriptionConfirmation = cookies(context).subscriptionConfirmation;
    }
    const podcastId = response?.data?.id;
    let episodeResponse = [];

    const params = queryString.stringify({
      status: 1,
    });

    if (response?.data?.audio_type === 'podcast') {
      episodeResponse = await episodeList(podcastId, false, params);
    } else {
      episodeResponse = await audioSeriesEpisodeList(podcastId, false, params);
    }

    if (episodeResponse?.status === 200) {
      return {
        podcastData: response?.data,
        episodeData: episodeResponse?.data,
        isPodcast: response?.data?.audio_type === 'podcast',
        host,
        slug,
        token,
        subscriptionConfirmation,
        spotifyShowUri: query.spotifyShowUri,
      };
    }
    return {
      podcastData: response?.data,
      episodeData: {},
      isPodcast: response?.data?.audio_type === 'podcast',
      host,
      slug,
      token,
      subscriptionConfirmation,
      spotifyShowUri: query.spotifyShowUri,
    };
  }
  return {};
};

ProductPublicPage.propTypes = {
  podcastData: PropTypes.object,
  episodeData: PropTypes.object,
  host: PropTypes.string,
  slug: PropTypes.string,
  subscriptionConfirmation: PropTypes.string,
  token: PropTypes.string,
  isPodcast: PropTypes.bool,
  spotifyShowUri: PropTypes.string,
};

export default ProductPublicPage;
