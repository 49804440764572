import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import useLang from 'context/LanguageContext';
import { translate as t } from 'utils/helpers';
import PropTypes from 'prop-types';
import styles from './Popup.module.scss';

const MessagePopup = ({ isOpen, hideModal, title, message }) => {
  const { lang } = useLang();
  return (
    <Modal
      className={styles.modalParent}
      show={isOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={hideModal}>
      <Modal.Body className={styles.modalBody}>
        <h6 className={styles.modalTitle}>{title}</h6>
        <p className={styles.modalTitle} styles={{fontSize: "1.175rem;"}}>{message}</p>
        <span onClick={hideModal}>
          <a href='#' className={`${styles.modalBtn} btn btn-primary compact`}>
            {t(lang, 'common')('ok')}
          </a>
        </span>
      </Modal.Body>
    </Modal>
  );
};

MessagePopup.propTypes = {
  isOpen: PropTypes.bool,
  hideModal: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default MessagePopup;
