import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import { translate as t, supportedLanguages } from '../../utils/helpers';

export const renderSpinner = () => <Spinner animation="grow" />;
const PodcastSubscription = ({
  styles,
  subscribeToPodcast,
  podcastData,
  subBtnLoading,
  currency,
  lang,
  isPodcast,
  isEpisode,
}) => {
  const plan =
    podcastData?.coupon_data?.plan === 'annual' ? 'yearly' : 'monthly';
  const podcastPrice =
    plan === 'yearly' ? podcastData.annual_price : podcastData?.price;
  const getButtonText = () => {
    let text = '';
    if (isPodcast) {
      if (isEpisode) {
        text = t(lang, 'podcast')('subscribeBtnWithoutPrice');
      } else {
        text = t(lang, 'podcast')('subscribe', {
          podcastPrice,
          currency,
          planType: plan,
        });
      }
    } else if (isEpisode) {
      text = t(lang, 'podcast')('purchaseBtnWithoutPrice');
    } else {
      text = t(lang, 'podcast')('purchase', { podcastPrice, currency });
    }
    return text;
  };

  function renderWrapper(childRenderer) {
    if (isPodcast && !isEpisode)
      return (
        <div
          className={isPodcast && !isEpisode ? 'btn compact p-0' : ''}
          style={{
            maxHeight: 'unset',
            borderRadius: 'unset',
            cursor: 'unset',
          }}>
          {childRenderer()}
        </div>
      );
    return <>{childRenderer()}</>;
  }

  return (
    <div
      className={`${styles.cta} sticky-cta podcast-subscribe-cta ${
        isEpisode ? 'hide-mobile' : ''
      } ${
        isPodcast && podcastData?.annual_price_enabled ? styles.spaciousCta : ''
      }`}>
      {renderWrapper(() => (
        <>
          <Button
            id={`podcast${podcastData.id}SubscriptionButton`}
            variant="primary"
            onClick={() => (subBtnLoading ? '' : subscribeToPodcast())}
            className="btn btn-primary compact"
            // style={{
            //   background: podcastData.button_color,
            //   color: podcastData.text_color,
            //   borderColor: podcastData.button_color,
            // }}
            disabled={subBtnLoading}>
            {subBtnLoading ? renderSpinner() : getButtonText()}
          </Button>
          {isPodcast && !isEpisode && (
            <div
              style={{
                textAlign: 'center',
                marginTop: '.5rem',
                zIndex: 20,
                fontSize: '1.0rem',
                color: 'black',
                fontWeight: 'normal',
              }}>
              {!podcastData?.coupon_data &&
                podcastData?.annual_price_enabled &&
                t(lang, 'podcast')('subscribeBtnAnnualMessage', {
                  podcastData,
                  currency,
                })}
            </div>
          )}
        </>
      ))}
    </div>
  );
};

PodcastSubscription.propTypes = {
  styles: PropTypes.object,
  subscribeToPodcast: PropTypes.func,
  podcastData: PropTypes.object,
  subBtnLoading: PropTypes.bool,
  currency: PropTypes.string,
  lang: PropTypes.oneOf(supportedLanguages),
  isPodcast: PropTypes.bool,
  isEpisode: PropTypes.bool,
};

export default PodcastSubscription;
