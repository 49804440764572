import { axios, getAuthToken } from '.';

const validateSpotifyLinking = async (payload) => {
  const url = `api/validate-spotify-linking/`;
  const token = getAuthToken();
  const response = await axios.post(url, payload, { ...token });
  return response;
};

const initializeSpotifyLinking = async (payload) => {
  const url = `api/initialize-spotify-linking/`;
  const token = getAuthToken();
  const response = await axios.post(url, payload, { ...token });
  return response;
};

const spotifyEntryPointChecker = async (params) => {
  const url = `api/entrypoint-checker/?${params}`;
  const tokenHeaders = getAuthToken();
  const response = await axios.get(url, { ...tokenHeaders });
  return response;
};

const spotifyIngestion = async (slug) => {
  const url = `api/spotify-ingestion/${slug}/`;
  const token = getAuthToken();
  const response = await axios.post(url, null, { ...token });
  return response;
};

const spotifyIngestionDeactivate = async (slug) => {
  const url = `api/spotify-ingestion/${slug}/`;
  const token = getAuthToken();
  const response = await axios.delete(url, { ...token });
  return response;
};

const unlinkSpotify = async () => {
  const url = `api/unlink-spotify/`;
  const token = getAuthToken();
  const response = await axios.post(url, null, { ...token });
  return response;
};

const spotifyStatusRetrieve = async (slug) => {
  const url = `api/spotify-ingestion/${slug}/`;
  const tokenHeaders = getAuthToken();
  const response = await axios.get(url, { ...tokenHeaders });
  return response;
};

export {
  validateSpotifyLinking,
  initializeSpotifyLinking,
  spotifyEntryPointChecker,
  spotifyIngestion,
  spotifyIngestionDeactivate,
  unlinkSpotify,
  spotifyStatusRetrieve,
};
