import { useEffect, useState } from 'react';
import { audiosPaginated, episodesPaginated } from '../apis/podcast';

export default function usePodcastEpisodeListHook(pageNumber, isPodcast, id) {
  const [loading, setLoading] = useState(false);
  const [audios, setAudios] = useState([]);
  const [trailer, setTrailerEpisode] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  useEffect(() => {
    setAudios([]);
  }, [id]);
  useEffect(async () => {
    if (id) {
      const controller = new AbortController();
      setLoading(true);
      if (isPodcast) {
        const response = await episodesPaginated(id, {
          params: {
            status: 1,
            page: pageNumber,
          },
          signal: controller.signal,
        });
        if (response?.status === 200) {
          setTrailerEpisode(response?.data?.trailer);
          if (response?.data?.results?.length) {
            setAudios((prevAudios) => [
              ...prevAudios,
              ...response?.data?.results?.filter(
                (item) =>
                  item?.status_display !== 'Unpublished' && item?.type !== 2,
              ),
            ]);
          }
          setHasMore(!!response.data.next);
        }
        setLoading(false);
      } else {
        audiosPaginated(id, {
          params: {
            status: 1,
            page: pageNumber,
          },
          signal: controller.signal,
        }).then((res) => {
          if (res?.data) {
            setTrailerEpisode(res?.data?.trailer);
            if (res?.data?.results?.length) {
              setAudios((prevAudios) => [
                ...prevAudios,
                ...res?.data?.results?.filter(
                  (item) =>
                    item?.status_display !== 'Unpublished' && item?.type !== 2,
                ),
              ]);
            }
            setHasMore(!!res.data.next);
            setLoading(false);
          }
        });
      }
      return () => controller.abort();
    }
    return true;
  }, [pageNumber, id]);
  return { loading, audios, setAudios, trailer, setTrailerEpisode, hasMore, setHasMore };
}
